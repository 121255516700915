import { graphql } from "gatsby"
import React from "react"
import NumberFact from "../../atoms/NumberFact"
import BgColor from "../../layouts/BgColor"
import DatoImage from "../DatoImage"
import * as styles from "./DatoNumberFacts.module.scss"

const DatoNumberFacts = (props: Queries.DatoNumberFactsFragment) => {
  //console.log('Rendering DatoNumberFacts', props)

  if (!props.numberFacts?.length) return null

  return (
    <div className={styles.container}>
      {props.image && <DatoImage className={styles.image} {...props.image} alt={props.image.alt || ""} />}
      <BgColor color={props.textBackground ?? undefined} className={styles.content}>
        {props.title && <h2 className={styles.title}>{props.title}</h2>}

        <div className={styles.numbers + " layout-container"}>
          {/* <NumberFact number={"1,234,567.00200"} description={"itm.description"} /> */}
          {props.numberFacts.map((itm, i) => {
            return itm?.number ? (
              <NumberFact key={i} number={itm.number} description={itm.description ?? undefined} />
            ) : null
          })}
        </div>
      </BgColor>
    </div>
  )
}

export default DatoNumberFacts

export const query = graphql`
  fragment DatoNumberFacts on DatoCmsNumberFactSet {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    image {
      ...DatoImageDefault
    }
    title
    numberFacts {
      number
      description
    }
    textBackground
  }
`
