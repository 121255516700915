import React from "react"
import * as styles from "./NumberFact.module.scss"

const splitString = (strVal: string, regex: RegExp) => {
  const delimiters = strVal.match(regex) // välimerkit
  const parts = [strVal] // osat välimerkkien välissä
  for (let i = 0; delimiters && i < delimiters.length; i++) {
    const [a, ...b] = parts[i].split(delimiters[i])
    parts[i] = a
    parts[i + 1] = b.join(delimiters[i])
  }

  return [parts, delimiters]
}

const RunningNumber = (props: { number: string }) => {
  const output = React.useRef<HTMLElement>(null)

  const strVal = props.number // alkuperäinen syöte
  const numVal = parseInt(strVal.replace(/(\s|,|\.)/g, "")) // poistetaan numerosta kaikki " ", "," ja "." välimerkit

  const [parts, delimiters] = splitString(strVal, /(\s|,|\.)/g)

  const lastDelim = delimiters && delimiters[delimiters.length - 1] // viimeinen, yleensä desimaalimerkki

  //console.log(strVal, parts, delimiters)

  let animId: number, scrollId: number

  React.useEffect(() => {
    let progress = 0
    const duration = 5000
    let startTime = 0

    window.addEventListener("scroll", scrolling)

    function scrolling(e: Event) {
      cancelAnimationFrame(scrollId)
      scrollId = requestAnimationFrame(scrollHandler)
    }
    function scrollHandler() {
      if (output.current && window.innerHeight > output.current?.getBoundingClientRect().top) {
        if (!startTime) {
          startTime = Date.now() + 300 // 300 ms viive
          anim()
        }
      }
    }
    scrollHandler()

    function anim() {
      if (!parts) return

      progress = Math.max(Math.min((Date.now() - startTime) / duration, 1), 0)

      if (progress < 1) {
        const v = Math.round(ease(numVal, progress, 10)).toString() // animoitu numero ilman välimerkkejä
        let ind = 0 // apunumero, jolla otetaan animoidusta arvosta oikean pituisia paloja
        let val = "" // output string
        for (let i = parts.length - 1; i >= 0; i--) {
          const partLength = parts[i].length
          ind += partLength

          if (delimiters && delimiters[i]) {
            val = delimiters[i] + val // lisätään seuraava välimerkki
          }

          val = v.substr(Math.max(v.length - ind, 0), partLength) + val // lisätään uusi pala numeron alkuun

          // viimeinen osa ennen desimaalimerkkiä
          if (i == parts.length - 1 && (lastDelim == "." || lastDelim == ",")) {
            // animoitu numero on lyhyempi kuin ennen desimaalimerkkiä oleva osa
            if (v.length <= partLength) {
              // lisätään nollat ja desimaalimerkki
              val = `0${lastDelim}${zeros(partLength - v.length)}${val}`
            }
          }

          // numero on lyhyempi kuin nykyinen mitta
          if (v.length <= ind) {
            break
          }
        }
        if (output?.current) output.current.innerHTML = val

        animId = requestAnimationFrame(anim)
      } else {
        if (output?.current) output.current.innerHTML = strVal
      }
    }

    function zeros(count: number) {
      let z = ""
      while (z.length < count) z += "0"
      return z
    }

    function ease(value: number, progress: number, exp = 3) {
      return (1 - Math.pow(1 - progress, exp)) * value
    }

    return () => {
      cancelAnimationFrame(animId)
      cancelAnimationFrame(scrollId)
      window.removeEventListener("scroll", scrolling)
    }
  }, [output])

  return <span ref={output} />
}

type NumberFactProps = {
  number: string
  description?: string
}

const NumberFact = (props: NumberFactProps) => {
  // pilkotaan syötetty arvo numeroihin, ja muihin osiin, esim yksiköt
  const [parts, numbers] = splitString(props.number, /((\d+[\s,.]{1})*\d)+/g) // numeroarvoissa mahdolliset välimerkit: " ", "," ja "."

  if (!parts || !numbers) return null

  return (
    <div className={styles.numberFact}>
      <p className={styles.number}>
        {parts.map((part, i) => {
          return (
            <React.Fragment key={i}>
              {part && <span>{part}</span>}
              {numbers[i] && <RunningNumber number={numbers[i]} />}
            </React.Fragment>
          )
        })}
      </p>
      <p className={styles.description}>{props.description}</p>
    </div>
  )
}

export default NumberFact
